<template>
	<section>

		<div class="banner">
			<div class="section">
				<div class="section-content banner-con">
					<!-- <div class="top-title">招募渠道合作伙伴</div>
					<div class="top-text">
						<p>代理区域保护，广告、培训、运营、资源全方位赋能</p>
						<p>助力伙伴持续高速成长</p>
					</div> -->
				</div>
			</div>
		</div>
		 <div class="intro">
            <div class="section">
                <div class="section-content">
                    <div class="intro-part1">

                        <div class="intro-title">
                            <!-- <img src="https://oss.ocean.xdangjia.com/dev_gorex/admin/avatar/1631582385541.png"> -->
                            <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/jumalogo_img.png">
                            <span>选择我们的理由！</span>
                            
                        </div>
                        <div class="intro-box">
                            <div class="intro-item">
                                <p class="intro-i-title">资深技术</p>
                                <p class="intro-i-operate">聚码SaaS系统，行业资深工程师设计打造客户无需关注技术问题，专注于商业运营</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">品牌设计</p>
                                <p class="intro-i-operate">聚码联合品牌设计师，可定制个性化设计包括企业LOGO、VI、包装设计等</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">安全稳定</p>
                                <p class="intro-i-operate">聚码采用阿里云的服务器与数据库主架构多层防护保障系统安全稳定的运行</p>
                            </div>
                        </div>
                    </div>
                    <div class="intro-part intro-part2">
                        <div>
                            <div class="intro-title">
                                <!-- <img src="https://oss.ocean.xdangjia.com/dev_gorex/admin/avatar/1631582385541.png"> -->
                                <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/jumalogo_img.png">
                                <span>定制开发</span>
                            </div>
                            <p class="intro-i-operate">聚码提供定制开发方案，帮助企业搭建个性化的营销体系。技术团队拥有多年移动互联网项目开发经验与品牌设计团队为客户量身定制设计，满足客户需求与期望</p>
                        </div>
                        
                        <div class="part2m">
                            <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/custom_bg.jpg">
                        </div>
                    </div>
                    <div class="intro-part intro-part3">
                        <div class="intro-title">
                            <!-- <img src="https://oss.ocean.xdangjia.com/dev_gorex/admin/avatar/1631582385541.png"> -->
                            <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/jumalogo_img.png">
                            <span>定制业务</span>
                        </div>
                        <p class="intro-i-operate active">云商城一站式技术服务平台提供各类定制服务，以面向各类商户的业务需求场景。按您的实际业务场景可选择一种或多种不同定制方案，助力客户商业策划快速启动</p>
                        <div class="intro-box active">
                            <div class="intro-item">
                                <p class="intro-i-title">小程序</p>
                                <p class="intro-i-operate">无需下载、无需安装，媲美APP的使用体验，是当下商户的首选平台</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">公众号</p>
                                <p class="intro-i-operate">打造品牌的必备平台，可以积累精准粉丝，借助平台的流量分发实现快速增长</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">H5</p>
                                <p class="intro-i-operate">可开展创意营销活动，功能强大，效果华丽展示与互动结合具有优秀的营销能力</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">电脑官网</p>
                                <p class="intro-i-operate">当下互联网时代的标配，企业品牌官网是展示企业、展示产品的重要环节</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">手机网站</p>
                                <p class="intro-i-operate">方便客户手机直接阅览公司产品与相关信息实现全方位的营销方案</p>
                            </div>
                        </div>
                       

                    </div>
                    <div class="intro-part intro-part4">
                        <div class="intro-title">
                            <!-- <img src="https://oss.ocean.xdangjia.com/dev_gorex/admin/avatar/1631582385541.png"> -->
                            <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/jumalogo_img.png">
                            <span>定制流程</span>
                        </div>
                        <p class="intro-i-operate active">云商城提供多种类型的营销功能供您使用，以面向各类商户的业务需求场景。按您的实际业务场景可选择启用不同的营销活动，搭配基础功能以开展多种形式的营销方案</p>


                        <div class="process-box">
                        	<div class="process-item">
                        		<i class="iconfont icon-cz-ldpg" style="margin-top: -12px;"></i>
                        		<div class="intro-item">
	                                <p class="intro-i-title">评估</p>
	                                <p class="intro-i-operate">客户可通过描述或者提供参考网站，我们评估开发所需时间和费用</p>
	                            </div>
                        	</div>
                        	<div class="process-item">
                        		<i class="iconfont icon-design"></i>
                        		<div class="intro-item">
	                                <p class="intro-i-title">设计</p>
	                                <p class="intro-i-operate">工程师和设计师将根据客户需求设计界面和功能流程</p>
	                            </div>
                        	</div>
                        	<div class="process-item">
                        		<i class="iconfont icon-shebeikaifa"></i>
                        		<div class="intro-item">
	                                <p class="intro-i-title">研发</p>
	                                <p class="intro-i-operate">根据设计图纸和功能流程文档进行开发</p>
	                            </div>
                        	</div>
                        	<div class="process-item">
                        		<i class="iconfont icon-houqiweihuweihuweihuguanli"></i>
                        		<div class="intro-item">
	                                <p class="intro-i-title">维护</p>
	                                <p class="intro-i-operate">支持多级会员，积分累计或支付年费</p>
	                            </div>
                        	</div>
                        </div>
                    </div>
                    <div class="intro-part intro-part5">
                        <div class="intro-title">
                            <!-- <img src="https://oss.ocean.xdangjia.com/dev_gorex/admin/avatar/1631582385541.png"> -->
                            <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/jumalogo_img.png">
                            <span>我们的服务</span>
                        </div>
                        <p class="intro-i-operate active">聚码对售后服务非常重视，为确保解决客户在软件使用过程中遇到的问题，我们安排专业工程师与专业设计师全程多对一服务，协助解决各类问题，永久免费提供使用指导</p>
                        <div class="intro-box active">
                            <div class="intro-item">
                                <p class="intro-i-title">资深工程师</p>
                                <p class="intro-i-operate">开发团队有丰富的从业经验，在移动互联网领域开发过多个业务类型的项目</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">品牌设计师</p>
                                <p class="intro-i-operate">聚码联合品牌设计师提供设计服务，包括企业运营设计、包装设计、VI设计服务</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">全国连锁服务</p>
                                <p class="intro-i-operate">聚码在全国各大城市设立服务中心，建立品牌服务渠道，提供更便捷的服务</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">永久免费指导</p>
                                <p class="intro-i-operate">每个客户设立专项服务群，聚码工程师与设计师直接为客户提供指导服务</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">定期免费升级</p>
                                <p class="intro-i-operate">聚码SaaS系统将持续不断的进行功能升级与漏洞修复，持续开发最新版本</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">支持功能定制</p>
                                <p class="intro-i-operate">现有功能无法满足客户需求时，提供新功能或新版本的开发定制服务</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">协助账号申请</p>
                                <p class="intro-i-operate">聚码系统接入的第三方账号，我们将提供协助客户开通第三方账号的服务</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">协助审核上线</p>
                                <p class="intro-i-operate">第三方账号需要审核上线的，我们也将协助客户提供合格的资料顺利通过审核</p>
                            </div>
                            <div class="intro-item">
                                <p class="intro-i-title">协助运营设计</p>
                                <p class="intro-i-operate">客户策划营销活动，我们提供营销内容设计服务，包括界面设计、海报设计服务</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>
<script type="text/javascript">
	export default {
    data(){
        return {
        	hha: 'wrewe'
        }
    },
	mounted(){
		console.log('this is partners')
	},
	methods: {

	},
  
  // router
}

</script>
<style type="text/css" scoped>
	.banner{
		width: 100%;
		height: 500px;
		background: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/customBg.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-color: rgba(255,255,255,0.1);
    	background-blend-mode: multiply;
		color: #333333;
		overflow: hidden;
	}
	.banner-con{
		padding-left: 120px;
	}
	.top-title{
		font-size: 24px;
		line-height: 24px;
		font-weight: bolder;
		margin-top: 80px;
	}
	.top-text{
		font-size: 15px;
		font-weight: 300;
		padding-top: 6px;
	}


	.intro{
        width: 100%;
        background-color: #fff;
        padding: 60px 0;
    }
    .intro .section-content{
        padding: 0 80px;
    }
    .intro-part{
        margin-top: 60px;
    }

    .intro-title{
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        color: #333;
    }
    .intro-title img{
        width: 34px;
        height: auto;
        margin-right: 12px;
    }
    .intro-box{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        color: #333;
    }
    .intro-box.active{
        margin-top: 20px;
    }
    .intro-box.grey{
        color: #909399;
    }
    .intro-item{
        width: 33.33%;
        margin: 20px 0;
        
        padding-right: 40px;
    }
    .intro-item:nth-of-type(3n){
        padding-right: 0;
    }
    .intro-i-title{
        font-size: 16px;
        font-weight: bold;
    }
    .intro-i-operate{
        font-size: 14px;
        padding-top: 8px;
    }
    .intro-i-operate.active{
        width: 60%;
    }
    .intro-part2{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .part2m img{
        width: 448px;
        margin-left: 180px;
    }

    .process-box{
    	width: 100%;
    	display: flex;
    	align-items: flex-start;
    	justify-content: space-between;
    }
    .process-item{
    	width: 25%;
    	display: flex;
    	align-items: center;
    }
    .process-item .intro-item{
    	width: 100%;
    }
    .process-item .intro-process .intro-i-title{
    	display: flex;
    	align-items: center;
    	color: #0088ff;
    }
    .process-item .iconfont{
    	font-size: 60px;
    	color: #333;
    	margin-right: 10px;
    	font-weight: initial;
    	color: #0088ff;
    }
	
</style>